<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        التحكم في المواليد والفرق
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/home/'+id)"
        />
      </template>
    </Toolbar>
    <div class="form-card" v-if="edit">
      <div class="mb-3">
        <label for="leaguesId" class="form-label">
          المواليد المشاركه
        </label>

        <Fieldset v-for="item of listEdit" :key="item.id">
          <template #legend>
            {{ item.yearId.name }} ({{ item.yearsTeams.length }})
            <!-- <Button
              icon="pi pi-minus"
              v-if="item.yearsTeams.length > 1"
              @click="removeItem(item.yearsTeams[item.yearsTeams.length - 1])"
              class="p-button-danger p-ml-2"
            />
            <Button
              icon="pi pi-plus"
              v-if="item.yearsTeams.length < item.yearId.limitTeam"
              @click="
                addToItem(item.yearsTeams[item.yearsTeams.length - 1], item.id)
              "
              class="p-button-success p-ml-2"
            /> -->
          </template>
          <p v-for="itm of item.yearsTeams" :key="itm.id">
            #{{ itm.countTeam }} - {{ itm.name }}
          </p>
        </Fieldset>
      </div>

      <div class="mb-3 text-center">
        <!-- <Button
          label="تعديل"
          icon="pi pi-pencil"
          class="p-ml-2 p-button-info"
          @click="update()"
        /> -->
<!-- 
        <b-button v-b-modal.modal-1 variant="success" v-if="listOpend.length > 0">
          اضافه مواليد اخري
        </b-button>

        <b-modal id="modal-1" title="اضافه مواليد اخري">
          <div class="row mb-3" v-for="item of listOpend" :key="item.id">
            <div class="col-md-6">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="true"
                  :id="`years-${item.id}`"
                  v-model="item.addYear"
                  name="year"
                />
                <label class="form-check-label" :for="`years-${item.id}`">
                  {{ item.name }}
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <select
                v-if="item.addYear"
                class="form-select form-control"
                id="yearTeamNumber"
                name="yearTeamNumber"
                v-model="item.addYearTeamNumber"
              >
                <option
                  v-for="item of item.limitTeam"
                  :key="item"
                  :value="item"
                  >{{ item }}</option
                >
              </select>
            </div>
          </div>

          <template #modal-footer="{ cancel }">
            <b>Custom Footer</b>
            <b-button size="sm" variant="success" @click="save()">
              حفظ
            </b-button>
            <b-button size="sm" variant="danger" @click="cancel()">
              الغاء
            </b-button>
          </template>
        </b-modal> -->
      </div>
    </div>
    <div class="form-card" v-else>
      <div class="mb-3">
        <label for="leaguesId" class="form-label">
          المواليد المشاركه
        </label>
        <div class="row mb-3" v-for="item of list" :key="item.id">
          <div class="col-md-6">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :value="true"
                :id="`years-${item.id}`"
                v-model="item.addYear"
                name="year"
              />
              <label class="form-check-label" :for="`years-${item.id}`">
                {{ item.name }}
              </label>
            </div>
          </div>
          <div class="col-md-6">
            <select
              v-if="item.addYear"
              class="form-select form-control"
              id="yearTeamNumber"
              name="yearTeamNumber"
              v-model="item.addYearTeamNumber"
            >
              <option
                v-for="item of item.limitTeam"
                :key="item"
                :value="item"
                >{{ item }}</option
              >
            </select>
          </div>
        </div>
      </div>

      <div class="mb-3 text-center">
        <Button
          label="حفظ"
          icon="pi pi-pencil"
          class="p-ml-2 p-button-info"
          @click="save()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const academie = JSON.parse(localStorage.user);
    return {
      list: [],
      listEdit: [],
      id: null,
      academieId: academie?.id,
      edit: false,
      listOpend: [],
    };
  },
  methods: {
    save() {
      this.$http
        .post(`years/createYearsAcademie`, {
          leaguesId: this.id,
          academieId: this.academieId,
          list: this.list,
        })
        .then(
          () => {
            this.edit = true;
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافه بنجاح',
              life: 3000,
            });
            // this.edit= true;
            this.$router.push('/');
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },
    removeItem(item) {
      this.$http.post(`years/deleteYearsAcademie`, item).then(() => {
        this.getData();
      });
    },
    addToItem(item, yearsAcademieId) {
      this.$http
        .post(`years/addYearsAcademie`, { ...item, yearsAcademieId })
        .then(() => {
          this.getData();
        });
    },
    getData() {
      this.$http
        .get(
          `years/findAllYearsAcademie?leaguesId=${this.id}&academieId=${this.academieId}`,
        )
        .then(
          (res) => {
            if (res.data.length > 0) {
              this.edit = true;
              this.listEdit = res.data;

              const mapList = this.listEdit.map((itm) => itm.yearId.id);
              this.listOpend = this.list.filter(
                (el) => !mapList.includes(el.id),
              );
            }
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`years?leaguesId=${this.id}`).then(
        (res) => {
          // this.list = res.data;
          for (const item of res.data) {
            this.list.push({
              id: item.id,
              addYear: false,
              name: item.name,
              addYearTeamNumber: 1,
              limitTeam: item.limitTeam,
            });
          }
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );

      this.getData();
    }
  },
};
</script>
